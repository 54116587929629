import GridView from "./GridView";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import config from "../config.js";

export default function SearchView() {

  let { query } = useParams();
  let [ items, setItems ] = useState([]);

  useEffect(() => {
    if (!query) return;
    let textOnly = !!config.textOnly;
    let url = `${config.BASE_URL}/search${textOnly?"_text":""}?q=${query}`;
    fetch(url).then(r => r.json()).then(d => {
      setItems(d);
    });
  }, [query])
  
  return (
    <GridView items={items}/>
  )
}